<template>
	<main>
		<h1 v-if="user.id">
			Edit user
		</h1>
		<h1 v-else>
			Create user
		</h1>

		<div class="columns">
			<div>
				<label for="first_name">First name *</label>
				<input id="first_name" v-model="user.firstName" type="text" autocomplete="off" />
			</div>

			<div>
				<label for="last_name">Last name *</label>
				<input id="last_name" v-model="user.lastName" type="text" autocomplete="off" />
			</div>
		</div>

		<div class="columns">
			<div>
				<label for="email">Email *</label>
				<input id="email" v-model="user.email" type="email" autocomplete="off" />
			</div>

			<div>
				<label for="password">Password</label>
				<input id="password" v-model="user.password" type="password" autocomplete="off" placeholder="Leave empty to keep current" />
			</div>
		</div>

		<div class="columns">
			<div>
				<BoolInput name="allow_lti_override" label="Allow LTI override" :checked="user.allowLtiOverride" @input="checked => user.allowLtiOverride = checked" />
			</div>

			<div v-if="!user.id">
				<BoolInput name="new_user_email" label="Send password reset email to user" :checked="user.notifyUser" @input="checked => user.notifyUser = checked" />
			</div>
		</div>

		<div class="courses">
			<table>
				<thead>
					<tr>
						<th scope="col">
							Courses
						</th>
						<th v-for="role in allRoles" :key="role.name" scope="col">
							{{ role.label }}
						</th>
						<th scope="col">
							None
						</th>
					</tr>
				</thead>
				<tbody>
					<tr class="global">
						<td>
							Global
						</td>
						<td v-for="role in allRoles" :key="role.name" scope="col">
							<BoolInput v-if="role.name !== 'student'" :radio="true" name="global" :checked="userHasRole(role.name)" @input="toggleRole(role.name)" />
						</td>
						<td scope="col">
							<BoolInput name="global" :radio="true" :checked="userHasRole(null)" @input="toggleRole(null)" />
						</td>
					</tr>
					<tr v-for="course in courses" :key="course.id">
						<td>
							{{ course.name }}
						</td>
						<td v-for="role in allRoles" :key="role.name" scope="col">
							<BoolInput :name="course.id" :radio="true" :checked="userHasRole(role.name, course.id)" @input="toggleRole(role.name, course.id)" />
						</td>
						<td scope="col">
							<BoolInput :name="course.id" :radio="true" :checked="userHasRole(null, course.id)" @input="toggleRole(null, course.id)" />
						</td>
					</tr>
				</tbody>
			</table>
		</div>

		<div v-if="canRemoveFromClass" class="classes">
			<table>
				<thead>
					<tr>
						<th scope="col" colspan="3">
							Classes
						</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="c in classes" :key="c.id">
						<td>
							{{ c.name }}
						</td>
						<td>
							{{ c.code }}
						</td>
						<td scope="col">
							<BaseButton type="link" @click="() => removeClass(c)">
								<IconCross :width="16" :height="16" /><span>Remove</span>
							</BaseButton>
						</td>
					</tr>
				</tbody>
			</table>
		</div>

		<div>
			<BaseButton @click="saveChanges">
				Save changes
			</BaseButton>

			<BaseButton v-if="!isSelf && userId" type="delete" @click="deleteUser">
				Delete
			</BaseButton>
		</div>
	</main>
</template>

<script>
	import Backend from '../../inc/backend';
	import BaseButton from '../../components/BaseButton.vue';
	import defaultRoles from '../../inc/userRoles';
	import Bus from '../../inc/bus';
	import {hasRole, isAdmin, isTeacher, roleGuard} from '../../inc/auth';
	import BoolInput from '../../components/BoolInput';
	import Router from '../../inc/router';
	import Store from '../../inc/store';
	import IconCross from '../../components/icons/IconCross';

	export default {
		...roleGuard('admin'),
		components: {
			BaseButton,
			BoolInput,
			IconCross
		},
		data() {
			return {
				user: {
					roles: [],
					notifyUser: true
				},
				courses: [],
				classes: []
			};
		},
		computed: {
			fullName() {
				return this.user.firstName + ' ' + this.user.lastName;
			},
			allRoles() {
				return defaultRoles;
			},
			userId() {
				return this.$route.params.id;
			},
			isSelf() {
				return Store.user.id === this.userId;
			},
			canRemoveFromClass() {
				return isAdmin() || isTeacher() || hasRole('Classes', 'any', 'teacher');
			}
		},
		created() {
			if(this.userId) {
				Backend.get('users/' + this.userId).then(res => {
					this.user = res.data;
				});
			}

			Backend.get('courses?admin=1').then(res => {
				this.courses = res.data;
			});

			if(this.canRemoveFromClass) {
				Backend.get('classes?user=' + this.userId).then(res => {
					this.classes = res.data;
				});
			}
		},
		methods: {
			saveChanges() {
				if(this.userId) {
					Backend.put('users/' + this.userId, this.user)
						.then(() => Bus.emit('success', 'Changes saved!'));
				}
				else {
					Backend.post('users', this.user)
						.then(res => {
							this.$set(this.user, 'id', res.data.id);

							return Router.push({name: 'AdminUsersEdit', params: {id: res.data.id}});
						})
						.then(() => Bus.emit('success', 'Changes saved!'));
				}
			},
			deleteUser() {
				this.$modal.show('dialog', {
					title: 'Delete user?',
					text: 'This will permanently delete this user. This action is irreversible. Do you want to continue?',
					buttons: [
						{
							title: 'Cancel',
							handler: () => {
								this.$modal.hide('dialog');
							}
						},
						{
							title: 'Delete',
							class: 'vue-dialog-button danger',
							handler: () => {
								Backend.delete('users/' + this.userId).then(() => {
									this.$modal.hide('dialog');
									Router.push({name: 'AdminUsers'});
								});
							}
						}
					]
				});
			},
			toggleRole(level, courseId = null) {
				const id = courseId ? `Courses/${courseId}` : `Tenants/${Store.tenant.id}`;

				this.user.roles = this.user.roles.filter(r => r.id !== id);

				if(level) {
					this.user.roles.push({id, level});
				}
			},
			userHasRole(role, courseId) {
				const id = courseId ? `Courses/${courseId}` : `Tenants/${Store.tenant.id}`;

				if(!role) {
					return !this.user.roles.some(r => r.id === id);
				}

				return this.user.roles.some(r => r.id === id && r.level === role);
			},
			removeClass(c) {
				if(!this.canRemoveFromClass) {
					return;
				}

				this.$modal.show('dialog', {
					title: 'Remove user from class?',
					text: `This will permanently remove this user from the class ${c.name}. This action is irreversible. Do you want to continue?`,
					buttons: [
						{
							title: 'Cancel',
							handler: () => {
								this.$modal.hide('dialog');
							}
						},
						{
							title: 'Remove',
							class: 'vue-dialog-button danger',
							handler: () => {
								Backend.post('classes/leave?user=' + this.userId, {class: c.id})
									.then(() => Backend.get('classes?user=' + this.userId))
									.then(res => {
										this.classes = res.data;
										this.$modal.hide('dialog');
									});
							}
						}
					]
				});
			}
		}
	};
</script>

<style lang="scss" scoped>
	.columns {
		display: flex;
		gap: $default_padding;

		> * {
			flex: 1;
		}
	}

	table {
		max-width: none;
		table-layout: fixed;

		label {
			margin: 0;
		}

		.global td {
			border-bottom: 2px solid rgba($color__dark, 0.2);
		}
	}

	.courses {
		th + th,
		td + td {
			width: 72px;
			padding: 0;
			text-align: center;
		}
	}

	.classes::v-deep {
		td:last-child {
			text-align: right;

			button {
				line-height: 1.5em;
			}
		}
	}
</style>